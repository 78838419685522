import {
  fetchTable,
  fetchEditColumnsData,
  postEditColumnsTable,
  fetchStores,
  fetchSellers,
  fetchClusters,
  fetchCampaigns,
  fetchIndicators,
} from '@/features/SalesReportNew/data';
import { getExportJobDetails } from '@/features/SalesReportNew/services';
import { cancelExportImportJob } from '@/services/audiences';
import { SelectedSegment, SelectedPreset } from '@/features/SalesReportNew/models';
import isEmpty from 'lodash/isEmpty';

const salesReportNew = {
  namespaced: true,
  state: {
    // table
    table: {
      header: [],
      body: [],
      summaryRow: [],
    },
    preset: 'campaign',
    metric: {
      key: 'campaign',
      name: 'Campanha',
    },
    pagination: {
      page: 1,
      limit: 10,
      count: 0,
    },
    limitRange: [10, 25, 50, 100],
    query: [],
    isLoading: false,
    hasError: false,
    isEmpty: false,
    isTableExpanded: false,
    isEditColumnsVisible: false,

    // edit table metrics
    metricsAndDimensions: [],
    checkedMetricsAndDimensions: [],
    selectedMetricsAndDimensions: [],
    defaultSelectedMetricsAndDimensions: [],
    metricsAndDimensionsOrdering: [],
    isEditColumnsLoading: false,
    keyDimensionsByPreset: [],

    // export table
    batchId: '',
    progress: 0,
    urlFile: '',
    isModalProgressVisible: false,
    isModalExportSuccessVisible: false,
    isModalExportErrorVisible: false,

    // filters
    contactType: '',
    originSale: '',
    campaignId: '',
    sellerId: '',
    storeId: '',
    cluster: '',
    selectedSegment: '',
    segments: [
      { id: 'all', name: 'all' },
      { id: 'store', name: '_store' },
      { id: 'seller', name: '_seller' },
      { id: 'campaign', name: '_campaign' },
      { id: 'cluster', name: '_cluster' },
    ],
    hasSomeSegmentApplied: false,
    optionsFromSelectedSegment: [],
    isLoadingAllFilters: {
      isLoadingStores: false,
      isLoadingSellers: false,
      isLoadingCampaigns: false,
      isLoadingClusters: false,
    },
    defaultList: [],
    stores: [],
    sellers: [],
    clusters: [],
    campaigns: [],

    // kpis / graphic
    kpis: {},
    isIndicatorsLoading: false,
    hasIndicatorsError: false,
    graphic: {},
    hasDataOnGraphic: false,
  },
  mutations: {
    // table
    SET_IS_LOADING(state, isLoading) {
      state.isLoading = isLoading;
    },
    SET_HAS_ERROR(state, hasError) {
      state.hasError = hasError;
    },
    SET_EMPTY_TABLE(state, isEmpty) {
      state.isEmpty = isEmpty;
    },
    SET_TABLE(state, tableData) {
      state.table.header = tableData.header;
      state.table.body = tableData.body;
      state.table.summaryRow = tableData.summaryRow;
    },
    SET_ORDER_BY(state, payload) {
      state.orderBy = payload;
    },
    SET_QUERY(state, query) {
      const { column } = query;
      const filterByColumn = state.query.map(query => query.column);

      if (filterByColumn.includes(column)) {
        const filteredQuery = state.query.filter(query => query.column !== column);
        state.query = [...filteredQuery, query];
      } else {
        state.query = [...state.query, query];
      }
    },
    CLEAR_QUERY_SEARCH(state, column) {
      if (!column) return (state.query = []);
      return (state.query = state.query.filter(query => query.column !== column));
    },
    SET_IS_TABLE_EXPANDED(state, isExpanded) {
      state.isTableExpanded = isExpanded;
    },
    // table metrics options
    SET_PRESET(state, preset) {
      state.preset = preset;
    },
    SET_METRIC(state, metric) {
      state.metric = metric;
    },
    // edit table columns
    SET_METRICS_AND_DIMENSIONS(state, metricsAndDimensions) {
      state.metricsAndDimensions = metricsAndDimensions;
    },
    SET_SELECTED_METRICS_AND_DIMENSIONS(state, metricsAndDimensions) {
      state.selectedMetricsAndDimensions = metricsAndDimensions;
      state.defaultSelectedMetricsAndDimensions = metricsAndDimensions;
    },
    SET_IS_EDIT_COLUMNS_VISIBLE(state, isVisible) {
      state.isEditColumnsVisible = isVisible;
    },
    SET_CHECKED_METRICS_AND_DIMENSIONS(state, checked) {
      state.checkedMetricsAndDimensions = checked;
    },
    DELETE_CHECKED_METRIC_AND_DIMENSION(state, key) {
      state.selectedMetricsAndDimensions = state.selectedMetricsAndDimensions.filter(item => item.key !== key);
      state.metricsAndDimensionsOrdering = state.metricsAndDimensionsOrdering.filter(item => item.key !== key);
    },
    SET_UPDATE_CHECKED_SELECTED_METRICS_AND_DIMENSIONS(state, metric) {
      state.selectedMetricsAndDimensions = [...state.selectedMetricsAndDimensions, metric];
      state.metricsAndDimensionsOrdering = [...state.metricsAndDimensionsOrdering, metric];
    },
    SET_DEFAULT_SELECTED_METRICS_AND_DIMENSIONS(state) {
      state.selectedMetricsAndDimensions = state.defaultSelectedMetricsAndDimensions;
    },
    SET_IS_EDIT_COLUMNS_LOADING(state, isLoading) {
      state.isEditColumnsLoading = isLoading;
    },
    SET_METRICS_AND_DIMENSIONS_ORDERING(state, metricsAndDimensionsOrdering) {
      state.metricsAndDimensionsOrdering = metricsAndDimensionsOrdering;
    },
    SET_KEY_DIMENSIONS_BY_PRESET(state) {
      const presetDimensionsMap = {
        [SelectedPreset.CAMPAIGN]: ['CAMPAIGN_NAME'],
        [SelectedPreset.SELLER]: ['ORDER_STORE_NAME', 'ORDER_SELLER_NAME', 'ORDER_SELLER_ENROLMENT'],
        [SelectedPreset.STORE]: ['ORDER_STORE_NAME', 'STORE_FINAL'],
        [SelectedPreset.STORE_PLACE]: ['STORE_CITY', 'STORE_STATE', 'STORE_REGION'],
        [SelectedPreset.DATE]: ['DATE_FINAL', 'ORDER_YEAR', 'ORDER_MONTH', 'ORDER_DAY', 'ORDER_WEEK'],
        [SelectedPreset.SALES]: [
          'CAMPAIGN_NAME',
          'ORDER_STORE_NAME',
          'CONTACT_STORE_NAME',
          'ORDER_SELLER_ENROLMENT',
          'CONTACT_SELLER_ENROLMENT',
          'CUSTOMER_ID',
        ],
      };
      state.keyDimensionsByPreset = presetDimensionsMap[state.preset] || [];
    },
    // export table
    SET_BATCH_ID(state, batchId) {
      state.batchId = batchId;
    },
    SET_IS_MODAL_PROGRESS_VISIBLE(state, boolean) {
      state.isModalProgressVisible = boolean;
    },
    SET_IS_MODAL_EXPORT_ERROR_VISIBLE(state, boolean) {
      state.isModalExportErrorVisible = boolean;
    },
    SET_PROGRESS(state, progress) {
      state.progress = progress;
    },
    SET_URL_FILE(state, urlFile) {
      state.urlFile = urlFile;
    },
    SET_IS_MODAL_EXPORT_SUCCESS_VISIBLE(state, boolean) {
      state.isModalExportSuccessVisible = boolean;
    },
    // filters
    SET_CONTACT_TYPE(state, contact) {
      state.contactType = contact;
    },
    SET_ORIGIN_SALE(state, sale) {
      state.originSale = sale;
    },
    SET_HAS_SOME_SEGMENT_APPLIED(state, boolean) {
      state.hasSomeSegmentApplied = boolean;
    },
    SET_OPTIONS_FROM_SELECTED_SEGMENT(state, optionsFromSelectedSegment) {
      state.optionsFromSelectedSegment = optionsFromSelectedSegment;
    },
    SET_SEGMENT_SELECTED_ID(state, selectedSegmentId) {
      const filteredById = state.optionsFromSelectedSegment.filter(item => item.isChecked).map(item => item.value);
      const filteredByName = state.optionsFromSelectedSegment.filter(item => item.isChecked).map(item => item.label);

      state.sellerId = selectedSegmentId === SelectedSegment.SELLER ? filteredById.join(',') : [];
      state.storeId = selectedSegmentId === SelectedSegment.STORE ? filteredById.join(',') : [];
      state.campaignId = selectedSegmentId === SelectedSegment.CAMPAIGN ? filteredById.join(',') : [];
      state.cluster = selectedSegmentId === SelectedSegment.CLUSTER ? filteredByName.join(',') : [];
    },
    SET_RESET_SEGMENT(state) {
      state.campaignId = [];
      state.sellerId = [];
      state.storeId = [];
      state.cluster = [];
      state.defaultList = [];
    },
    SET_SELECTED_SEGEMENT(state, segement) {
      state.selectedSegment = segement;
    },
    SET_IS_LOADING_ALL_FILTERS(state, payload) {
      const { name } = payload;
      state.isLoadingAllFilters[name] = payload.isLoading;
    },
    SET_DEFAULT_LIST(state, defaultList) {
      state.defaultList = defaultList;
    },
    SET_STORES(state, storesList) {
      state.stores = storesList;
    },
    SET_SELLERS(state, sellersList) {
      state.sellers = sellersList;
    },
    SET_CLUSTERS(state, clustersList) {
      state.clusters = clustersList;
    },
    SET_CAMPAIGNS(state, campaignsList) {
      state.campaigns = campaignsList;
    },
    // kpis / graphic
    SET_KPIS(state, kpis) {
      state.kpis = kpis;
    },
    SET_IS_INDICATORS_LOADING(state, isLoading) {
      state.isIndicatorsLoading = isLoading;
    },
    SET_HAS_INDICATORS_ERROR(state, hasError) {
      state.hasIndicatorsError = hasError;
    },
    SET_GRAPHIC(state, data) {
      state.graphic = data;
    },
    SET_HAS_DATA_ON_GRAPHIC(state, hasData) {
      state.hasDataOnGraphic = hasData;
    },
  },
  actions: {
    // table
    setFetchTable({ commit, state }) {
      const preset = state.preset;
      const limit = state.pagination.limit;
      const page = state.pagination.page;
      const orderBy = state.orderBy;
      const query = state.query;
      const contactType = state.contactType;
      const originSale = state.originSale;
      const campaignId = state.campaignId;
      const sellerId = state.sellerId;
      const storeId = state.storeId;
      const cluster = state.cluster;

      commit('SET_IS_LOADING', true);
      commit('SET_HAS_ERROR', false);
      commit('SET_EMPTY_TABLE', false);

      fetchTable({
        preset,
        limit,
        page,
        orderBy,
        query,
        contactType,
        originSale,
        campaignId,
        sellerId,
        storeId,
        cluster,
      })
        .then(response => {
          if (response?.data.length === 0) {
            commit('SET_EMPTY_TABLE', true);
          }
          const tableData = {
            header: [],
            body: [],
            summaryRow: [],
          };

          tableData.header = response?.series;
          tableData.body = response?.data;
          tableData.summaryRow = response?.dataCalcTotal;
          state.pagination.count = response?.totalCount;

          commit('SET_TABLE', tableData);
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          commit('SET_IS_LOADING', false);
        });
    },
    setOrderBy({ commit }, payload) {
      commit('SET_ORDER_BY', payload);
    },
    setQuery({ commit }, payload) {
      commit('SET_QUERY', payload);
    },
    setClearSearch({ commit }, payload) {
      commit('CLEAR_QUERY_SEARCH', payload);
    },
    setIsTableExpanded({ commit }, isExpanded) {
      commit('SET_IS_TABLE_EXPANDED', isExpanded);
    },
    // table metrics options
    setPreset({ commit, dispatch }, preset) {
      commit('SET_PRESET', preset);
      commit('SET_KEY_DIMENSIONS_BY_PRESET');
      dispatch('setClearSearch');
      dispatch('updateCheckedMetricsAndDimensions', { key: [] });
      dispatch('setOrderBy', '');
      dispatch('updateSelectedMetricsAndDimensions', []);
      dispatch('setFetchTable');
    },
    setMetric({ commit }, metric) {
      commit('SET_METRIC', metric);
    },
    // edit table columns
    setEditColumnsIsVisible({ commit }, payload) {
      commit('SET_IS_EDIT_COLUMNS_VISIBLE', payload);
    },
    loadMetricsAndDimensions({ commit, dispatch }, preset) {
      commit('SET_IS_EDIT_COLUMNS_LOADING', true);

      fetchEditColumnsData(preset)
        .then(response => {
          const metricsAndDimensions = response?.tableMetrics;
          const ordering = response?.ordering;

          commit('SET_METRICS_AND_DIMENSIONS', metricsAndDimensions);
          commit('SET_METRICS_AND_DIMENSIONS_ORDERING', ordering);
          dispatch('setCheckedDefaultMetricsAndDimensions', ordering);
        })
        .finally(() => {
          commit('SET_IS_EDIT_COLUMNS_LOADING', false);
        });
    },
    updateSelectedMetricsAndDimensions({ commit }, payload) {
      commit('SET_SELECTED_METRICS_AND_DIMENSIONS', payload);
    },
    setCheckedDefaultMetricsAndDimensions({ state, dispatch }, items) {
      state.defaultSelectedMetricsAndDimensions = items;

      dispatch('updateSelectedMetricsAndDimensions', items);
    },
    updateCheckedMetricsAndDimensions({ state, commit, dispatch }, payload) {
      const { key, type } = payload;

      if (!key.length) {
        return;
      }
      commit('SET_CHECKED_METRICS_AND_DIMENSIONS', key);

      if (type === 'draggable') return;

      commit('SET_ORDER_BY', '');
      commit('SET_IS_LOADING', true);

      const metricsAndDimensions = key.join(',');

      postEditColumnsTable(metricsAndDimensions, state.preset, 'sales').then(response => {
        if (response) {
          dispatch('setFetchTable');
        }
      });
    },
    deleteCheckedMetricsAndDimensions({ commit }, payload) {
      commit('DELETE_CHECKED_METRIC_AND_DIMENSION', payload);
    },
    setUpdateCheckedSelectedMetricsAndDimensions({ commit }, payload) {
      commit('SET_UPDATE_CHECKED_SELECTED_METRICS_AND_DIMENSIONS', payload);
    },
    setResetToDefaultMetricsAndDimensions({ commit, dispatch }) {
      commit('SET_DEFAULT_SELECTED_METRICS_AND_DIMENSIONS');
      dispatch('updateCheckedMetricsAndDimensions', { key: [''] });
    },
    updateDefaultPreset({ commit }, preset) {
      commit('SET_PRESET', preset);
    },
    setKeyDimensionsByPrest({ commit }) {
      commit('SET_KEY_DIMENSIONS_BY_PRESET');
    },
    // export table
    setBatchId({ commit }, batchId) {
      commit('SET_BATCH_ID', batchId);
    },
    setIsModalProgressVisible({ commit }, boolean) {
      commit('SET_IS_MODAL_PROGRESS_VISIBLE', boolean);
    },
    setIsModalExportSuccessVisible({ commit }, boolean) {
      commit('SET_IS_MODAL_EXPORT_SUCCESS_VISIBLE', boolean);
    },
    setIsModalExportErrorVisible({ commit }, boolean) {
      commit('SET_IS_MODAL_EXPORT_ERROR_VISIBLE', boolean);
    },
    setCancelExportJobDetails({ state }) {
      if (state.batchId) cancelExportImportJob(state.batchId).then();
    },
    setReset({ commit }) {
      commit('SET_BATCH_ID', '');
      commit('SET_URL_FILE', '');
      commit('SET_PROGRESS', 0);
    },
    setExportJobDetails({ commit, state, dispatch }) {
      dispatch('setIsModalExportSuccessVisible', false);
      const batchId = state.batchId;

      if (!batchId) return;

      getExportJobDetails(batchId).then(({ data }) => {
        const errorCodes = ['job-cancelled-with-errors', 'clickhouse-select-fail'];
        const response = data?.data;
        const progress = response?.result?.progress;
        const urlFile = response?.result?.url;
        const cancelledByUser = response?.result?.code === 'job-cancelled-by-user';
        const cancelledWithErrors = errorCodes.includes(response?.result?.code);

        commit('SET_PROGRESS', progress);

        if (cancelledByUser) {
          commit('SET_PROGRESS', 0);
          dispatch('setIsModalProgressVisible', false);
        } else if (cancelledWithErrors) {
          commit('SET_PROGRESS', 0);
          dispatch('setIsModalProgressVisible', false);
          setTimeout(() => {
            dispatch('setIsModalExportErrorVisible', true);
          }, 1000);
        } else if (!urlFile) {
          setTimeout(() => {
            dispatch('setExportJobDetails');
          }, 3000);
        } else {
          commit('SET_URL_FILE', urlFile);
          setTimeout(() => {
            dispatch('setIsModalExportSuccessVisible', true);
            dispatch('setReset');
          }, 2000);
        }
      });
    },
    // filters
    setContactType({ commit, dispatch }, contact) {
      commit('SET_CONTACT_TYPE', contact);
      dispatch('setFetchTable');
      dispatch('setFetchIndicators');
    },
    setOriginSale({ commit, dispatch }, sale) {
      commit('SET_ORIGIN_SALE', sale);
      dispatch('setFetchTable');
      dispatch('setFetchIndicators');
    },
    setResetFilters({ commit }) {
      commit('SET_CONTACT_TYPE', '');
      commit('SET_ORIGIN_SALE', '');
    },
    setUpdateCheckedStatus({ commit }, payload) {
      commit('SET_OPTIONS_FROM_SELECTED_SEGMENT', payload);
    },
    setResetSegment({ commit }) {
      commit('SET_RESET_SEGMENT');
    },
    updateDefaultList({ commit }, payload) {
      commit('SET_DEFAULT_LIST', payload);
    },
    setSelectedSegement({ commit, state }, payload) {
      commit('SET_SELECTED_SEGEMENT', payload);

      if (payload === SelectedSegment.SELLER) return commit('SET_OPTIONS_FROM_SELECTED_SEGMENT', state.sellers);
      if (payload === SelectedSegment.STORE) return commit('SET_OPTIONS_FROM_SELECTED_SEGMENT', state.stores);
      if (payload === SelectedSegment.CAMPAIGN) return commit('SET_OPTIONS_FROM_SELECTED_SEGMENT', state.campaigns);
      if (payload === SelectedSegment.CLUSTER) return commit('SET_OPTIONS_FROM_SELECTED_SEGMENT', state.clusters);
    },
    setApplyFilters({ commit, dispatch, state }, payload) {
      if (state.optionsFromSelectedSegment.some(item => item.isChecked)) {
        commit('SET_SEGMENT_SELECTED_ID', state.selectedSegment);
      } else {
        dispatch('setResetSegment');
      }
      commit('SET_HAS_SOME_SEGMENT_APPLIED', true);
      dispatch('updateDefaultList', payload);
      dispatch('setFetchTable');
      dispatch('setFetchIndicators');
    },
    setFetchSegmentOptions({ dispatch }, selectedSegment) {
      if (selectedSegment === 'store') dispatch('setStores');
      if (selectedSegment === 'seller') dispatch('setSellers');
      if (selectedSegment === 'campaign') dispatch('setCampaigns');
      if (selectedSegment === 'cluster') dispatch('setClusters');
    },
    setStores({ commit }) {
      commit('SET_IS_LOADING_ALL_FILTERS', { name: 'isLoadingStores', isLoading: true });
      fetchStores()
        .then(response => {
          const data = response?.data;

          if (!data) {
            commit('SET_STORES', []);
            commit('SET_DEFAULT_LIST', []);
            return;
          }

          const formattedData = data?.map(item => {
            return { ...item, isChecked: false };
          });

          commit('SET_STORES', formattedData);
          commit('SET_DEFAULT_LIST', formattedData);
        })
        .catch(() => {
          commit('SET_STORES', []);
          commit('SET_DEFAULT_LIST', []);
        })
        .finally(() => {
          commit('SET_IS_LOADING_ALL_FILTERS', { name: 'isLoadingStores', isLoading: false });
        });
    },
    setSellers({ commit }) {
      commit('SET_IS_LOADING_ALL_FILTERS', { name: 'isLoadingSellers', isLoading: true });
      fetchSellers()
        .then(response => {
          if (!response) {
            commit('SET_SELLERS', []);
            commit('SET_DEFAULT_LIST', []);
            return;
          }

          const formattedData = response?.map(item => {
            return { ...item, isChecked: false };
          });

          commit('SET_SELLERS', formattedData);
          commit('SET_DEFAULT_LIST', formattedData);
        })
        .catch(() => {
          commit('SET_SELLERS', []);
          commit('SET_DEFAULT_LIST', []);
        })
        .finally(() => {
          commit('SET_IS_LOADING_ALL_FILTERS', { name: 'isLoadingSellers', isLoading: false });
        });
    },
    setClusters({ commit }) {
      commit('SET_IS_LOADING_ALL_FILTERS', { name: 'isLoadingClusters', isLoading: true });
      fetchClusters()
        .then(response => {
          if (!response) {
            commit('SET_CLUSTERS', []);
            commit('SET_DEFAULT_LIST', []);
            return;
          }

          const formattedData = response?.map(item => {
            return { ...item, isChecked: false };
          });

          commit('SET_CLUSTERS', formattedData);
          commit('SET_DEFAULT_LIST', formattedData);
        })
        .catch(() => {
          commit('SET_CLUSTERS', []);
          commit('SET_DEFAULT_LIST', []);
        })
        .finally(() => {
          commit('SET_IS_LOADING_ALL_FILTERS', { name: 'isLoadingClusters', isLoading: false });
        });
    },
    setCampaigns({ commit }) {
      commit('SET_IS_LOADING_ALL_FILTERS', { name: 'isLoadingCampaigns', isLoading: true });
      fetchCampaigns()
        .then(response => {
          if (!response) {
            commit('SET_CAMPAIGNS', []);
            commit('SET_DEFAULT_LIST', []);
            return;
          }

          const formattedData = response?.map(item => {
            return { ...item, isChecked: false };
          });

          commit('SET_CAMPAIGNS', formattedData);
          commit('SET_DEFAULT_LIST', formattedData);
        })
        .catch(() => {
          commit('SET_CAMPAIGNS', []);
          commit('SET_DEFAULT_LIST', []);
        })
        .finally(() => {
          commit('SET_IS_LOADING_ALL_FILTERS', { name: 'isLoadingCampaigns', isLoading: false });
        });
    },
    // kpis / graphic
    setFetchIndicators({ commit, state }) {
      commit('SET_IS_INDICATORS_LOADING', true);
      commit('SET_HAS_INDICATORS_ERROR', false);
      commit('SET_HAS_DATA_ON_GRAPHIC', true);

      const campaignId = state.campaignId;
      const sellerId = state.sellerId;
      const storeId = state.storeId;
      const cluster = state.cluster;
      const originSale = state.originSale;
      const contactType = state.contactType;

      fetchIndicators({ campaignId, storeId, sellerId, cluster, originSale, contactType })
        .then(response => {
          if (isEmpty(response?.graphics?.metrics?.revenue) || isEmpty(response?.graphics?.metrics?.totalSales)) {
            commit('SET_HAS_DATA_ON_GRAPHIC', false);
          }
          commit('SET_KPIS', response?.kpis);
          commit('SET_GRAPHIC', response?.graphics);
        })
        .catch(() => {
          commit('SET_HAS_INDICATORS_ERROR', true);
          commit('SET_HAS_DATA_ON_GRAPHIC', false);
        })
        .finally(() => {
          commit('SET_IS_INDICATORS_LOADING', false);
        });
    },
  },
  getters: {
    getTableBody: state => state.table.body,
    getTableSummaryRow: state => state.table.summaryRow,
    getTableHeader: state => state.table.header,
    getIsLoading: state => state.isLoading,
    getIsEmpty: state => state.isEmpty,
    getHasError: state => state.hasError,
    getPagination: state => state.pagination,
    getLimitRange: state => state.limitRange,
    getOrderBy: state => state.orderBy,
    getIsTableExpanded: state => state.isTableExpanded,
    getIsEditColumnsVisible: state => state.isEditColumnsVisible,
    getListOfMetricsAndDimensions: state => state.metricsAndDimensions,
    getPreset: state => state.preset,
    getSelectedMetricsAndDimensions: state => state.selectedMetricsAndDimensions,
    getCheckedMetricsAndDimensions: state => state.checkedMetricsAndDimensions,
    getDefaultMetricsLength: state => state.defaultSelectedMetricsAndDimensions.length,
    getBatchId: state => state.batchId,
    getIsModalProgressVisible: state => state.isModalProgressVisible,
    getProgress: state => state.progress,
    getUrlFile: state => state.urlFile,
    getIsModalExportSuccessVisible: state => state.isModalExportSuccessVisible,
    getIsModalExportErrorVisible: state => state.isModalExportErrorVisible,
    getIsEditColumnsLoading: state => state.isEditColumnsLoading,
    getMetricsAndDimensionsOrdering: state => state.metricsAndDimensionsOrdering,
    getSegments: state => state.segments,
    getHasSomeSegmentApplied: state => state.hasSomeSegmentApplied,
    getOptionsFromSelectedSegment: state => state.optionsFromSelectedSegment,
    getSelectedSegment: state => state.selectedSegment,
    getDefaultList: state => state.defaultList,
    getIsLoadingAllFilters: state => state.isLoadingAllFilters,
    getCampaignsId: state => state.campaignId,
    getSellersId: state => state.sellerId,
    getClusters: state => state.cluster,
    getStoresId: state => state.storeId,
    getKpis: state => state.kpis,
    getIsIndicatorsLoading: state => state.isIndicatorsLoading,
    getHasIndicatorsError: state => state.hasIndicatorsError,
    getGraphic: state => state.graphic,
    getHasDataOnGraphic: state => state.hasDataOnGraphic,
    getContactType: state => state.contactType,
    getOriginSale: state => state.originSale,
    getMetric: state => state.metric,
    getKeyDimensionsByPreset: state => state.keyDimensionsByPreset,
  },
};

export default salesReportNew;
